<template>
  <div id="game-members-credit" class="container-fluid">
    <div class="page-header mb-1 d-flex justify-content-between">
      <strong>เครดิตเกมของสมาชิก</strong>
      <div class="bg-light text-info rounded px-2 border-info">เครดิตคงเหลือ <strong>{{ creditBalance | amountFormat(2, '0.00') }}</strong></div>
    </div>

    <div class="alert alert-warning border-warning">
      *** หากไม่เจอรายชื่อสมาชิก ให้ทำการเปิดเกมให้สมาชิกที่หน้า "<strong><router-link :to="{name: 'GameActivate'}">ตั้งค่าเกม - เปิด/ปิด</router-link></strong>" ก่อน
    </div>

    <div class="d-flex justify-content-between align-items-center p-0 border-info rounded breadcrumb-nav">
      <ol class="breadcrumb px-2 py-1 m-0">
        <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
          <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
          <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
        </li>
      </ol>
      <div style="width: 250px; border-left: 1px solid #ced4da;" class="p-0">
        <input type="text" v-model="filter" class="form-control form-control-sm border-0" placeholder="ค้นหาสมาชิก...">
      </div>
    </div>

    <b-table
      class="table-game-account-credit mt-2"
      :fields="fields"
      :items="items"
      :show-empty="true"
      empty-text="ไม่มีข้อมูล"
      hover
      fixed
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #cell(index)="data">
        {{ (perPage*(currentPage-1)) + data.index + 1 }}
      </template>
      <template #cell(username)="data">
        <span>
          <a v-if="data.item.role==='Agent'" href="#" @click="toggleChildAccount(data.item.accountId)">{{ data.item.user.username }}</a>
          <span v-else>{{ data.item.user.username }}</span>
        </span>
      </template>
      <template #cell(role)="data">
        <div>
          <AccountRole :role="data.item.role" />
        </div>
      </template>
      <template #cell(firstName)="data">
        {{ data.item.user.firstName }}
      </template>
      <template #cell(balance)="data">
        <span class="text-success font-weight-bold">{{ data.item.balance | amountFormat(2, '0.00') }}</span>
      </template>
      <template #cell(manage)="data">
        <b-dropdown v-if="currentAccountId===data.item.createdBy" class="m-0 p-0 btn-edit" size="sm" variant="link" right>
          <template slot="button-content">
            <i class="fas fa-ellipsis-v"></i>
          </template>
          <b-dropdown-item @click="viewHistory(data.item.accountId)">
            <template>
              <span class="text-primary"><i class="far fa-eye"></i> ประวัติเครดิต</span>
            </template>
          </b-dropdown-item>
          <b-dropdown-item @click="deposit(data.item)">
            <template>
              <span class="text-success"><i class="far fa-plus-square"></i> เติมเครดิต</span>
            </template>
          </b-dropdown-item>
          <b-dropdown-item @click="withdraw(data.item)">
            <template>
              <span class="text-danger"><i class="far fa-minus-square"></i> ถอนเครดิต</span>
            </template>
          </b-dropdown-item>
        </b-dropdown>
        <button v-else class="btn btn-sm btn-primary" @click="viewHistory(data.item.accountId)"><i class="far fa-eye"></i> ประวัติเครดิต</button>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      align="right"
      size="md"
      class="my-0"
    ></b-pagination>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />

    <GameDepositModal :balance="creditBalance" :data="creditItem" :is-show="isShowDepositModal" @close="modalClosed" />
    <GameWithdrawModal :balance="creditBalance" :data="creditItem" :is-show="isShowWithdrawModal" @close="modalClosed" />
    <GameCreditModal :accountId="viewHistoryAccountId" :is-show="isShowCreditModal" @close="modalClosed" />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import GameDepositModal from './components/GameDepositModal'
import GameWithdrawModal from './components/GameWithdrawModal'
import GameCreditModal from './components/GameCreditModal'

export default {
  name: 'MemberCredits',
  components: {
    GameDepositModal,
    GameWithdrawModal,
    GameCreditModal
  },
  data() {
    return {
      isLoading: false,
      data: null,
      perPage: 25,
      currentPage: 1,
      filter: '',
      fields: [
        {key: 'index', label: 'ลำดับ', width: 80},
        {key: 'username', label: 'ชื่อผู้ใช้'},
        {key: 'role', label: 'ระดับ'},
        {key: 'firstName', label: 'ชื่อ'},
        {key: 'balance', label: 'เครดิตเกม', tdClass: "text-right", thClass: "text-right"},
        {key: 'manage', label: '', tdClass: "text-right"}
      ],
      creditItem: null,
      isShowDepositModal: false,
      isShowWithdrawModal: false,
      viewHistoryAccountId: null,
      isShowCreditModal: false
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    items() {
      return (this.data?.accounts || [])
    },
    Breadcrumbs() {
      return (this.data?.parents || [])
    },
    creditBalance() {
      return (this.data?.balance ?? 0)
    }
  },
  methods: {
    getData(parentAccountId=null) {
      this.isLoading = true
      GameService.getAccountCredits(parentAccountId).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleChildAccount(accountId) {
      this.getData(accountId)
    },
    onFiltered() {
      this.currentPage = 1
    },
    deposit(item) {
      this.creditItem = item
      this.isShowDepositModal = true
    },
    withdraw(item) {
      this.creditItem = item
      this.isShowWithdrawModal = true
    },
    viewHistory(accountId) {
      this.viewHistoryAccountId = accountId
      this.isShowCreditModal = true
    },
    modalClosed(needReload) {
      this.creditItem = null
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false
      this.isShowCreditModal = false

      if(needReload) {
        this.getData()
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss">
#game-members-credit {
  .breadcrumb-nav {
    background-color: #FFFFFF;
  }

  .table-game-account-credit {
    thead, tbody {
      background-color: #FFFFFF;
    }

    button.dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu.dropdown-menu-right {
      li {
        font-size: 90%;
      }
    }
  }
}
</style>
