<template>
  <b-modal
    ref="deposit-modal"
    hide-footer
    no-close-on-backdrop
    title="เติมเงิน"
    @show="resetModal"
    @hidden="resetModal"
    class="position-relative"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <div class="row">
        <div class="col-sm-6">
          <div class="mb-2">
            <span>จากบัญชี</span>
            <div class="text-center alert alert-primary p-1"><h2 class="m-0">{{ ownerUsername }}</h2></div>
          </div>
          <div class="mb-2">
            <span>ยอดคงเหลือ</span>
            <div class="text-center alert alert-primary p-1"><h2 class="m-0 text-right">{{ ownerBalance }}</h2></div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb-2">
            <span>เข้าบัญชี</span>
            <div class="text-center alert alert-success p-1"><h2 class="m-0">{{ memberUsername }}</h2></div>
          </div>
          <div class="mb-2">
            <span>ยอดคงเหลือ</span>
            <div class="text-center alert alert-success p-1"><h2 class="m-0 text-right">{{ memberBalance }}</h2></div>
          </div>
        </div>
      </div>

      <b-form-group
        label="จำนวนเงิน"
      >
        <b-form-input
          v-model="input.amount"
          type="number"
          :number="true"
          size="lg"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รายละเอียด"
      >
        <b-form-input
          v-model="input.note"
          type="text"
          size="lg"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="success" :disabled="isProcess">{{isProcess ? 'กำลังเติมเงิน...' : 'เติมเงิน'}}</b-button>
      </div>
    </form>

    <loading
      :active="isProcess"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="30"
      :width="30"
      color="#51CE8D"
      loader="dots"
    />
  </b-modal>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'
import cAlert from '@/helpers/alert'

export default {
  name: 'DepositModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      account: null,
      input: {
        uniqueCode: null,
        amount: null,
        note: null
      },
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    ownerUsername() {
      return this.$store.state.userProfile.username
    },
    ownerBalance() {
      return this.$store.state.account.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    memberUsername() {
      if(this.data) {
        return this.data.users[0].username
      }else{
        return ''
      }
    },
    memberBalance() {
      if(this.data) {
        return this.data.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return '0.00'
      }
    },
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input.amount = null
      this.input.note = null
    },
    showModal() {
      this.input.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      this.$refs['deposit-modal'].show()
    },
    hideModal() {
      this.$refs['deposit-modal'].hide()
    },
    handleSubmit() {

      if(this.input.amount < 0 || this.$store.state.account.balance < this.input.amount) {
        Swal.fire({
          title: 'จำนวนเงินไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return
      }

      this.isProcess = true
      ManageAccountService.deposit(this.data._id, this.input)
      .then((response)=>{
        if(response.success) {
          this.$store.dispatch('reloadAccount')
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เติมเงินเสร็จเรียบร้อย',
          })
        }else{
          throw response
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'เติมเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
