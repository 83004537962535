export default {
  path: 'setting-report',
  // component: { render (c) { return c('router-view') } },
  component: () => import('@/views/setting-report/TheContainer'),
  children: [
    {
      path: 'wait',
      name: 'WaitReward',
      component: () => import('@/views/setting-report/WaitReward')
    },
    {
      path: 'overall',
      name: 'RoundOverall',
      component: () => import('@/views/setting-report/RoundOverall')
    },
    {
      path: 'win-lose',
      name: 'RoundWinLose2',
      component: () => import('@/views/setting-report/RoundWinLose3')
    },
    {
      path: 'sets-overall',
      name: 'SetsOverall',
      component: () => import('@/views/setting-report/SetsOverall')
    },
    {
      path: 'member',
      name: 'RoundMemberReport',
      component: () => import('@/views/setting-report/RoundMemberReport')
    },
    {
      path: 'sets-member',
      name: 'SetsMemberReport',
      component: () => import('@/views/setting-report/SetsMemberReport')
    },
    {
      path: 'tickets',
      name: 'RoundMemberTickets',
      component: () => import('@/views/setting-report/RoundMemberTickets')
    },
    {
      path: 'limit',
      name: 'RoundLimitNumbers',
      component: () => import('@/views/setting-report/RoundLimitNumbers')
    }
  ]
}
