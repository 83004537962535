<template>
  <div id="game-activate" class="container-fluid">
    <div class="page-header mb-1">
      <strong>ตั้งค่าเกม - เปิด/ปิด</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <table class="table table-bordered table-striped table-pp">
      <thead>
        <tr>
          <th rowspan="3" width="60">ลำดับ</th>
          <th rowspan="2" width="60">เลือก</th>
          <th rowspan="2" width="200">
            ชื่อใช้งาน
            <b-form-checkbox
              v-model="showName"
              :value="true"
              :unchecked-value="false"
              class="mt-2 font-weight-normal"
            >
              แสดงชื่อ
            </b-form-checkbox>
          </th>
          <th rowspan="2" width="150">ระดับ</th>
          <th rowspan="2" width="150">เปิด/ปิด</th>
          <th :colspan="catItems.length+1">เปิด/ปิด หมวดหมู่</th>
        </tr>
        <tr>
          <th v-for="cat in catItems" :key="cat._id">{{ cat.name }}</th>
          <th></th>
        </tr>
        <tr>
          <th class="text-center border-0">
            <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
          </th>
          <td class="text-center border-0" colspan="2">
            <small>ตั้งค่ากับรายการที่เลือก</small>
          </td>
          <td class="text-center border-0">
            <b-form-checkbox
              v-model="bulkAvailable"
              name="check-button"
              switch
              :class="[bulkAvailable ? 'text-success' : 'text-danger']">
              {{ bulkAvailable ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
          </td>
          <td v-for="cat in catItems" :key="cat._id" class="text-center border-0">
            <b-form-checkbox
              v-model="bulkAvailableCats[cat._id]"
              name="check-button"
              switch
              :class="[bulkAvailableCats[cat._id] ? 'text-success' : 'text-danger']">
              {{ bulkAvailableCats[cat._id] ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
          </td>
          <th width="60" class="p-0 text-center border-0">
            <button @click="saveBulk" class="btn btn-success btn-sm">บันทึก</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center">{{ index+1 }}</td>
          <td class="text-center">
            <b-form-checkbox v-model="bulkAccountIds" :value="item.accountId"/>
          </td>
          <td class="text-center">
            <span v-if="item.role==='Agent' && item.isAvailable" class="link" @click="toggleChildAccount(item.accountId)">{{ item.user.username }}</span>
            <span v-else>{{ item.user.username }}</span>
            <small v-if="showName" class="text-secondary ml-1">({{ item.user.firstName }})</small>
          </td>
          <td class="text-center"><AccountRole :role="item.role" /></td>
          <td class="text-center">
            <b-form-checkbox
              v-model="item.isAvailable"
              name="check-button"
              switch
              @change="toggleChange(item)"
              :class="[item.isAvailable ? 'text-success' : 'text-danger']">
              {{ item.isAvailable ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
          </td>
          <td v-for="cat in catItems" :key="cat._id" class="text-center">
            <b-form-checkbox v-if="item.isAvailable" v-model="item.catSettings[cat._id]" name="check-button" switch @change="toggleChange(item)" :class="[item.catSettings[cat._id] ? 'text-success' : 'text-danger']">
              {{ item.catSettings[cat._id] ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
            <i v-else class="fas fa-times text-secondary"></i>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td class="text-center" :colspan="6 + catItems.length">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'GameActivate',
  data() {
    return {
      accountId: this.$store.state.account._id,
      data: null,
      showName: false,
      checkAll: false,
      bulkAccountIds: [],
      bulkAvailable: false,
      bulkAvailableCats: {}
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.breadcrumbs[this.breadcrumbs.length - 1].accountId
      }
    },
    breadcrumbs() {
      return this.data?.parents ?? []
    },
    catItems() {
      return (this.data?.categories ?? [])
      .map((cat)=>{
        return {
          _id: cat._id,
          name: cat.name
        }
      })
    },
    items() {
      return this.data?.accounts ?? []
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          this.bulkAccountIds.push(item.accountId)
        }
      }
    }
  },
  methods: {
    loadData() {
      GameService.getChildAccounts(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          this.bulkAvailableCats = (response.data?.categories ?? []).reduce((settings, cat)=>{
            settings[cat._id] = false
            return settings
          }, {})
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message,
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          if(e.status === 404) {
            this.$router.push({ name: 'Dashboard'})
          }
        })
      })
      .finally(()=>{
        this.resetBulk()
      })
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.loadData()
    },
    toggleChange(account) {
      const updateData = {
        accountIds: [account.accountId],
        isAvailable: account.isAvailable,
        catSettings: account.catSettings
      }
      this.updateStatus(updateData)
    },
    updateStatus(data) {
      GameService.updateGameActivate(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadData()
      })
    },
    resetBulk() {
      this.checkAll = false
      this.bulkAccountIds = []
      this.bulkAvailable = false
    },
    saveBulk() {
      if(!this.bulkAccountIds.length)
        return

      const updateData = {
        accountIds: this.bulkAccountIds,
        isAvailable: this.bulkAvailable,
        catSettings: this.bulkAvailableCats
      }
      this.updateStatus(updateData)
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss">
#game-activate {
  .table {

    th, td {
      .custom-control {
        &.custom-checkbox {
          padding-left: 2rem;
        }
      }
    }

    thead {
      tr {
        th {
          text-align: center;
          font-weight: normal;
          vertical-align: middle;
        }
      }
    }

    tbody {
      background-color: #FFFFFF;
      tr {
        td {
          vertical-align: middle;

          .link {
            color: #007BFF;
          }
          .link:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
