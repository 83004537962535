<template>
  <div id="round-winlose-report" class="pb-5 position-relative">
    <div class="page-header mb-1">
      <h4>คาดคะเน ได้-เสีย</h4>
    </div>

    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <div class="d-flex justify-content-between">
          <h5 class="card-title mb-0">
            <i class="fas fa-info-circle text-danger mr-2"></i>
            <span class="text-dark mr-2">เฉพาะ</span>
            <span class="text-primary mr-2">{{ roundName }}</span>
            <span class="text-dark mr-2">วันที่</span>
            <span class="text-primary mr-2">{{ roundDate }}</span>
            <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>

          <span>
            <!-- <button type="button" class="btn btn-warning border-warning btn-sm py-0 mr-2" @click="reload(false)">รีเฟรช</button>
            <small>(รีเฟรชใน {{countDown}} วินาที)</small> -->
            <button type="button" class="btn alert-warning border-warning btn-sm py-0" @click="reload(false)">รีเฟรชในอีก {{countDown}} วินาที</button>
          </span>
        </div>
      </b-card-header>

      <RoundWinLoseAll :roundData="roundData" :setReceive="setReceive" :data="dataItems" :total="total" :isLoading="isLoading" @reload="reload" />
    </b-card>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#51CE8D"
      loader="dots"
    />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import ReportService from "@/services/ReportService"
import RoundService from "@/services/RoundService"
import _ from 'lodash'

import RoundWinLoseAll from './components/RoundWinLoseAll'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundWinLose2',
  components: {
    RoundWinLoseAll
  },
  data() {
    return {
      data: null,
      roundData: null,
      isLoading: false,
      activeTab: 'WinLoseTop',
      setReceive: {
        threeNumberTop: 0,
        threeNumberTode: 0,
        twoNumberTop: 0,
        twoNumberBottom: 0,
        runTop: 0,
        runBottom: 0
      },
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      // return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
      return `หวย${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    dataItems() {
      const items = this.data?.items || []

      /**
       * 3 ตัวบน
       */
      const threeNumberTop = items.filter(item => item._id.type==='threeNumberTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.threeNumberTop.total
        return item
      })

      /**
       * 3 ตัวโต๊ด
       */
      const threeNumberTodeItems = items.filter(item => item._id.type==='threeNumberTode')
      const threeNumberTodeObjects = threeNumberTodeItems.reduce((todeItems, item)=>{
        todeItems[item._id.number] = item.reward
        return todeItems
      }, {})

      const threeNumberTode = threeNumberTodeItems.map((item)=>{
        const sps = item._id.number.split("")
        const todeWinNumbers = _.uniq([
          `${sps[0]}${sps[1]}${sps[2]}`,
          `${sps[0]}${sps[2]}${sps[1]}`,
          `${sps[1]}${sps[0]}${sps[2]}`,
          `${sps[1]}${sps[2]}${sps[0]}`,
          `${sps[2]}${sps[0]}${sps[1]}`,
          `${sps[2]}${sps[1]}${sps[0]}`
        ])

        /**
         * ยอดจ่าย 3 ตัวโต๊ดทั้งหมด
         */
        const todeWonAmount = todeWinNumbers.reduce((total, number)=>{
          total += (threeNumberTodeObjects?.[number] || 0)
          return total
        }, 0)

        item.winlose = todeWonAmount + this.total.threeNumberTode.total
        return item
      })

      /**
       * 2 ตัวบน
       */
      const twoNumberTop = items.filter(item => item._id.type==='twoNumberTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.twoNumberTop.total
        return item
      })

      /**
       * 2 ตัวล่าง
       */
      const twoNumberBottom = items.filter(item => item._id.type==='twoNumberBottom')
      .map((item)=>{
        item.winlose = item.reward + this.total.twoNumberBottom.total
        return item
      })

      /**
       * วิ่งบน
       */
      const runTop = items.filter(item => item._id.type==='runTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.runTop.total
        return item
      })

      /**
       * วิ่งล่าง
       */
      const runBottom = items.filter(item => item._id.type==='runBottom')
      .map((item)=>{
        item.winlose = item.reward + this.total.runBottom.total
        return item
      })

      return {
        threeNumberTop,
        threeNumberTode,
        twoNumberTop,
        twoNumberBottom,
        runTop,
        runBottom
      }
    },
    total() {
      return (this.data?.items || []).reduce((total, item)=>{
        total[item._id.type].amount += item.agentAmount
        total[item._id.type].comission += item.agentCommission
        total[item._id.type].total += item.agentTotal

        total.overall.amount += item.agentAmount
        total.overall.comission += item.agentCommission
        total.overall.total += item.agentTotal
        return total
      }, {
        threeNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        threeNumberTode: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        overall: {
          amount: 0,
          comission: 0,
          total: 0
        }
      })
    }
  },
  watch: {
    roundId(n, o) {
      if(n) {
        this.reload()
      }
    }
  },
  methods: {
    getReport(silent=false) {

      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType==='lottoset') {
        return this.$router.push({name: 'SetsOverall'})
      }

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      ReportService.getRoundOverall(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundSetting(this.roundId, true)
      .then((response)=>{
        if(response.success) {
          this.roundData = response.data
          this.setReceive = this.roundData.receive
        }else{
          throw new Error(response.data)
        }
      })
    },
    reload(silent=false) {
      this.loadRoundData()
      this.getReport(silent)
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.reload(true)
        }
      }, 1000)
    }
  },
  mounted() {
    this.reload()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
