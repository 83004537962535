<template>
  <div id="game-win-lose" class="container-fluid mb-5 pb-5">
    <div class="page-header mb-1">
      <strong>สรุปยอดเกม ได้-เสีย</strong>
    </div>

    <DateFilter :with-market-id="false" :defaultMarketIds="[]" @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <ol v-if="data" id="market-summary" class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#market-summary" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <table class="table table-bordered table-pp mb-0">
        <thead>
          <tr>
            <th rowspan="2" colspan="2">เกม</th>
            <th width="8%" rowspan="2" style="border-right-width: 2px;">ยอดเล่น</th>
            <th colspan="4" class="bg-member" style="border-right-width: 2px;">สมาชิก</th>
            <th colspan="4" class="bg-agent" style="border-right-width: 2px;">{{agentName}}</th>
            <th colspan="4" class="bg-company">บริษัท</th>
          </tr>
          <tr>
            <th width="6%" class="p-1 bg-member">ส่งออก</th>
            <th width="6%" class="p-1 bg-member">คอมฯ</th>
            <th width="6%" class="p-1 bg-member">ชนะ</th>
            <th width="6%" class="p-1 bg-member" style="border-right-width: 2px;">รวม</th>
            <th width="6%" class="p-1 bg-agent">ถือหุ้น</th>
            <th width="6%" class="p-1 bg-agent">คอมฯ</th>
            <th width="6%" class="p-1 bg-agent">ชนะ</th>
            <th width="6%" class="p-1 bg-agent" style="border-right-width: 2px;">รวม</th>
            <th width="6%" class="p-1 bg-company">ถือหุ้น</th>
            <th width="6%" class="p-1 bg-company">คอมฯ</th>
            <th width="6%" class="p-1 bg-company">ชนะ</th>
            <th width="6%" class="p-1 bg-company">รวม</th>
          </tr>
        </thead>
        <tbody v-for="item in reportGames" :key="item._id.gameId">
          <tr>
            <td width="40" class="p-0 text-center" style="cursor: pointer;" @click="showAccount(item)">
              <img :src="item.game.img" height="40" class="mx-auto d-block">
            </td>
            <td class="p-0 px-1 text-primary" style="cursor: pointer;" @click="showAccount(item)">{{item.game.name}} ({{item.game.creator.name}})</td>
            <td
              class="text-right px-1 text-success"
              style="border-right-width: 2px;">{{ item.amount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1 bg-member"
              :class="[{
                'text-success': item.memberAmount>0
              }, {
                'text-danger': item.memberAmount<0
              }]"
            >{{ item.memberAmount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1 bg-member"
              :class="[{
                'text-success': item.memberCommission>0
              }, {
                'text-danger': item.memberCommission<0
              }]"
            >{{ item.memberCommission | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1 bg-member"
              :class="[{
                'text-success': item.memberWon>0
              }, {
                'text-danger': item.memberWon<0
              }]"
            >{{ item.memberWon | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1 bg-member"
              :class="[{
                'text-success': item.memberTotal>0
              }, {
                'text-danger': item.memberTotal<0
              }]"
              style="border-right-width: 2px;"
            >{{ item.memberTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1 bg-agent"
              :class="[{
                'text-success': item.agentAmount>0
              }, {
                'text-danger': item.agentAmount<0
              }]">{{ item.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-agent"
              :class="[{
                'text-success': item.agentCommission>0
              }, {
                'text-danger': item.agentCommission<0
              }]">{{ item.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-agent"
              :class="[{
                'text-success': item.agentWon>0
              }, {
                'text-danger': item.agentWon<0
              }]">{{ item.agentWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-agent" style="border-right-width: 2px;"
              :class="[{
                'text-success': item.agentTotal>0
              }, {
                'text-danger': item.agentTotal<0
              }]">{{ item.agentTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1 bg-company"
              :class="[{
                'text-success': item.companyAmount>0
              }, {
                'text-danger': item.companyAmount<0
              }]">{{ item.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-company"
              :class="[{
                'text-success': item.companyCommission>0
              }, {
                'text-danger': item.companyCommission<0
              }]">{{ item.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-company"
              :class="[{
                'text-success': item.companyWon>0
              }, {
                'text-danger': item.companyWon<0
              }]">{{ item.companyWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 bg-company"
              :class="[{
                'text-success': item.companyTotal>0
              }, {
                'text-danger': item.companyTotal<0
              }]">{{ item.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
          <tr>
            <td colspan="15" class="p-0">
              <b-collapse :id="'collapse-'+item._id.gameId" :visible="showAccountGameId===item._id.gameId">
                <WinLoseGameByAccount
                  :showGameId="showAccountGameId"
                  :filters="filters"
                  :gameId="item._id.gameId"
                  :showName="showName"
                  @toggleAccount="toggleChildAccount"
                  @toggleMember="toggleMemberAccount"
                  class="p-2" />
              </b-collapse>
            </td>
          </tr>
        </tbody>
        <tbody v-if="isLoading && !reportGames.length">
          <tr>
            <td colspan="15" class="text-center">กรุณารอซักครู่</td>
          </tr>
        </tbody>
        <tbody v-if="!reportGames.length && !isLoading">
          <tr>
            <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="summary" v-if="reportGames.length">
      <strong>รวมทั้งหมด</strong>

      <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
        <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
          <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
          <a v-else href="#summary" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
        </li>
      </ol>

      <div class="card">
        <table class="market-win-lose-account table table-bordered table-info mb-0">
          <thead>
            <tr>
              <th rowspan="2">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="6%" rowspan="2">ระดับ</th>
              <th width="8%" rowspan="2" style="border-right-width: 2px;">ยอดเล่น</th>
              <th colspan="4" class="bg-member" style="border-right-width: 2px;">สมาชิก</th>
              <th colspan="4" class="bg-agent" style="border-right-width: 2px;">{{agentName}}</th>
              <th colspan="4" class="bg-company">บริษัท</th>
            </tr>
            <tr>
              <th width="6%" class="p-1 bg-member">ส่งออก</th>
              <th width="6%" class="p-1 bg-member">คอมฯ</th>
              <th width="6%" class="p-1 bg-member">ชนะ</th>
              <th width="6%" class="p-1 bg-member" style="border-right-width: 2px;">รวม</th>
              <th width="6%" class="p-1 bg-agent">ถือหุ้น</th>
              <th width="6%" class="p-1 bg-agent">คอมฯ</th>
              <th width="6%" class="p-1 bg-agent">ชนะ</th>
              <th width="6%" class="p-1 bg-agent" style="border-right-width: 2px;">รวม</th>
              <th width="6%" class="p-1 bg-company">ถือหุ้น</th>
              <th width="6%" class="p-1 bg-company">คอมฯ</th>
              <th width="6%" class="p-1 bg-company">ชนะ</th>
              <th width="6%" class="p-1 bg-company">รวม</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in accounts" :key="item.account.accountId">
              <td class="p-0 px-1 text-center">
                <a v-if="item.account.role==='Agent'" href="#summary" @click="toggleChildAccount(item.account.accountId)">{{ item.account.username }}</a>
                <a v-else href="#member-tickets" @click="toggleMemberAccount(item.account.accountId, null)">{{ item.account.username }}</a>
                <small v-if="showName" class="text-secondary ml-1">({{item.account.firstName}})</small>
              </td>
              <td class="text-center">
                <AccountRole :role="item.account.role" />
              </td>
              <td
                class="text-right px-1"
                :class="[{
                  'text-success': item.summary.amount>0
                }, {
                  'text-danger': item.summary.amount<0
                }]"
                style="border-right-width: 2px;"
              >{{ item.summary.amount | amountFormat(2, '0.00') }}</td>

              <td
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': item.summary.memberAmount>0
                }, {
                  'text-danger': item.summary.memberAmount<0
                }]"
              >{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': item.summary.memberCommission>0
                }, {
                  'text-danger': item.summary.memberCommission<0
                }]"
              >{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-member text-success"
              >{{ item.summary.memberWon | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': item.summary.memberTotal>0
                }, {
                  'text-danger': item.summary.memberTotal<0
                }]"
                style="border-right-width: 2px;"
              >{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>

              <td
                class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': item.summary.agentAmount>0
                }, {
                  'text-danger': item.summary.agentAmount<0
                }]"
              >{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': item.summary.agentCommission>0
                }, {
                  'text-danger': item.summary.agentCommission<0
                }]"
              >{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': item.summary.agentWon>0
                }, {
                  'text-danger': item.summary.agentWon<0
                }]"
              >{{ item.summary.agentWon | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-agent"
                style="border-right-width: 2px;"
                :class="[{
                  'text-success': item.summary.agentTotal>0
                }, {
                  'text-danger': item.summary.agentTotal<0
                }]"
              >{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>

              <td
                class="text-right px-1 bg-company"
                :class="[{
                  'text-success': item.summary.companyAmount>0
                }, {
                  'text-danger': item.summary.companyAmount<0
                }]"
              >{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-company"
                :class="[{
                  'text-success': item.summary.companyCommission>0
                }, {
                  'text-danger': item.summary.companyCommission<0
                }]"
              >{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-company"
                :class="[{
                  'text-success': item.summary.companyWon>0
                }, {
                  'text-danger': item.summary.companyWon<0
                }]"
              >{{ item.summary.companyWon | amountFormat(2, '0.00') }}</td>
              <td
                class="text-right px-1 bg-company"
                :class="[{
                  'text-success': item.summary.companyTotal>0
                }, {
                  'text-danger': item.summary.companyTotal<0
                }]"
              >{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="text-center">รวม</th>
              <th
                class="text-right px-1"
                style="border-right-width: 2px;"
                :class="[{
                  'text-success': summary.amount>0
                }, {
                  'text-danger': summary.amount<0
                }]"
              >{{ summary.amount | amountFormat(2, '0.00') }}</th>
              <th
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': summary.memberAmount>0
                }, {
                  'text-danger': summary.memberAmount<0
                }]"
              >{{ summary.memberAmount | amountFormat(2, '0.00') }}</th>
              <th
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': summary.memberCommission>0
                }, {
                  'text-danger': summary.memberCommission<0
                }]"
              >{{ summary.memberCommission | amountFormat(2, '0.00') }}</th>
              <th
                class="text-right px-1 bg-member"
                :class="[{
                  'text-success': summary.memberWon>0
                }, {
                  'text-danger': summary.memberWon<0
                }]"
              >{{ summary.memberWon | amountFormat(2, '0.00') }}</th>
              <th
                class="text-right px-1 bg-member"
                style="border-right-width: 2px;"
                :class="[{
                  'text-success': summary.memberTotal>0
                }, {
                  'text-danger': summary.memberTotal<0
                }]"
              >{{ summary.memberTotal | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': summary.agentAmount>0
                }, {
                  'text-danger': summary.agentAmount<0
                }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': summary.agentCommission>0
                }, {
                  'text-danger': summary.agentCommission<0
                }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-agent"
                :class="[{
                  'text-success': summary.agentWon>0
                }, {
                  'text-danger': summary.agentWon<0
                }]">{{ summary.agentWon | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-agent" style="border-right-width: 2px;"
                :class="[{
                  'text-success': summary.agentTotal>0
                }, {
                  'text-danger': summary.agentTotal<0
                }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-company"
                :class="[{
                  'text-success': summary.companyAmount>0
                }, {
                  'text-danger': summary.companyAmount<0
                }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-company"
                :class="[{
                  'text-success': summary.companyCommission>0
                }, {
                  'text-danger': summary.companyCommission<0
                }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-company"
                :class="[{
                  'text-success': summary.companyWon>0
                }, {
                  'text-danger': summary.companyWon<0
                }]">{{ summary.companyWon | amountFormat(2, '0.00') }}</th>
              <th class="text-right px-1 bg-company"
                :class="[{
                  'text-success': summary.companyTotal>0
                }, {
                  'text-danger': summary.companyTotal<0
                }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />

    <ViewMemberGameModal :isShow="isShowModal" :filters="modalFilters" @close="closeModal" />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import DateFilter from '@/views/win-lose-report/components/DateFilter'
import cAlert from '@/helpers/alert'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import WinLoseGameByAccount from './components/WinLoseGameByAccount'
import ViewMemberGameModal from './components/ViewMemberGameModal'

export default {
  name: 'GameWinLose',
  components: {
    DateFilter,
    WinLoseGameByAccount,
    ViewMemberGameModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        accountId: null,
        gameIds: [],
        startDate: null,
        endDate: null
      },
      Breadcrumbs: [],
      showName: false,
      showAccountGameId: null,
      isShowModal: false,
      modalFilters: {
        accountId: null,
        gameIds: null,
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    reportGames() {
      return _.orderBy((this.data?.games||[]), ['game.name'], ['asc'])
    },
    accounts() {

      if(!this.data)
        return []

      const reports = this.data?.accounts || []

      return _.orderBy(reports, ['account.username'], ['asc'])
    },
    summary() {
      return this.accounts.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberWon += item.summary.memberWon
        total.memberTotal += item.summary.memberTotal
        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentWon += item.summary.agentWon
        total.agentTotal += item.summary.agentTotal
        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyWon += item.summary.companyWon
        total.companyTotal += item.summary.companyTotal
        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberWon: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentWon: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyWon: 0,
        companyTotal: 0
      })
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...this.filters,
        startDate: filters.date.start,
        endDate: filters.date.end
      }

      console.log(this.filters)
      this.getReports()
    },
    getReports() {
      this.isLoading = true
      GameService.getGameWinLose(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
          this.Breadcrumbs = response.data.agent.parents
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleChildAccount(accountId) {
      this.filters.accountId = accountId
      this.getReports()
    },
    toggleMemberAccount(accountId, gameId) {
      this.modalFilters = {
        ...this.filters,
        accountId: accountId,
        gameIds: gameId?[gameId]:[],
      }
      this.isShowModal = true
    },
    showAccount(item) {
      if(this.showAccountGameId !== item._id.gameId)
        this.showAccountGameId = item._id.gameId
      else
        this.showAccountGameId = null
    },
    closeModal() {
      this.isShowModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: normal;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td, th {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
        line-height: 1.2;
      }
    }
  }
  tfoot {
    tr {
      td, th {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.game-win-lose-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 85%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
