<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy;{{new Date().getFullYear()}} Masterlot999</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
<style lang="scss">
.c-footer {
  background-color: #c8e6c9;
  color: #2e7d32;
}
</style>
